import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "datepicker"
    }}>{`DatePicker`}</h1>
    <p>{`Provide an initial value of type `}<inlineCode parentName="p">{`Date`}</inlineCode>{`. Specify the min/max range with `}<inlineCode parentName="p">{`in`}</inlineCode>{` and
choose between displaying the date or time with `}<inlineCode parentName="p">{`displayedComponents`}</inlineCode>{`.`}</p>
    <ContentBlock imgName="Form-datePicker.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
  struct DatePicker<Label> where Label : View
*/
struct FormDatePicker: View {
    @State private var date = Date()
    
    var body: some View {
        Form {
            DatePicker(selection: $date, in: ...Date(), displayedComponents: .date) {
                Text("Select a date")
            }\n
            DatePicker(selection: $date, in: ...Date(), displayedComponents: .hourAndMinute) {
                Text("Select a time")
            }
        }
    }
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "date-ranges"
    }}>{`Date Ranges`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`// Up to current date 
DatePicker(selection: $date, in: ...Date(), displayedComponents: .date) {
    Text("Select a date")
}

// Current date and after
DatePicker(selection: $date, in: Date()..., displayedComponents: .date) {
    Text("Select a date")
}

// Custom range (use Date types)
DatePicker(selection: $date, in: $startDate...$endDate, displayedComponents: .date) {
    Text("Select a date")
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      